.new_arrivals {
  background-color: #fff;
  padding: 0px 0px;
  .new_arrivalsheader {
    padding-top: 30px;
    border-top: 1px solid #ddd;
    h5 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: left;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
    }
  }
  .new_arrivals_main {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
    clear: both;
    width: 100%;
    span {
      position: relative;
      width: 100% !important;
    }

    // .jwellery {
    //   width: 100%;
    //   position: relative;
    //   background-color: #f7f7f7;
    //   padding: 0px;
    //   img {
    //     height: auto;
    //     width: 100%;
    //     background-color: #fff;
    //   }
    // }
    .new_arrivals_card {
      width: 100%;
      position: relative;
      height: 277px;
      clear: both;
      display: block;
      background-color: #f7f7f7;
      span {
        width: 100% !important;
      }

      img {
        width: 100% !important;
        object-fit: cover;
      }
      .new_arrivals_wishlist {
        position: absolute;
        right: 5px;
        top: 5px;
        button {
          background-color: transparent;
          border: 0px;
        }
      }
      .add_to_card {
        background-color: transparent;
        border: 0px;
        float: right;
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
        padding-top: 10px;
      }
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 0px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  float: right;
  text-transform: uppercase;
  background-image: url("../assets/images/arrow.png");
  background-repeat: no-repeat;
  background-position: center right;
  padding-right: 90px;
  &:hover {
    color: #ff1933;
    background-image: url("../assets/images/arrow-red.png");
  }
}
.best_ss_p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  p {
    color: #ff1933;
    font-size: 18px;
    font-weight: 600;
  }
  i {
    color: #ff1933;
    font-size: 20px;
    padding-right: 7px;
    font-size: 23px;
  }
  button {
    background-color: transparent;
    border: 0px;
  }
}
.text_p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0px;
  padding-top: 8px;
  color: #000;
  font-size: 16px;
  padding: 0 7px;
  padding-top: 7px;
  padding-bottom: 0px;
}
.card_new {
  background-color: #fbeded;
  transition: box-shadow 0.3s;
  padding-bottom: 3px;
  &:hover {
    box-shadow: 0px 4px 19px rgb(33 33 33 / 15%);
  }
}
